(function ($) {
  // @todo finalize sorting and filtering
  Drupal.behaviors.productGrid = {
    attach: function (context, settings) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      // var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        var arrowsDiv = $(this).parent().find('.carousel-controls');
        var dotsDiv = $(this).parent().find('.carousel-dots');
        var slidesShowCountSmall = $(this).data('slides-show-small');
        var slidesShowCountMedium = $(this).data('slides-show-medium');
        var slidesShowCountLarge = $(this).data('slides-show-large');
        var centerMode = $(this).parent().hasClass('product-grid--carousel--small-1') === true ? true : false;
        var centerPadding = $(this).parent().hasClass('product-grid--carousel--small-1') === true ? '60px' : '0';
        var settings = {
          appendArrows: arrowsDiv,
          arrows: true,
          appendDots: dotsDiv,
          dots: true,
          infinite: true,
          slidesToShow: slidesShowCountLarge,
          slidesToScroll: slidesShowCountLarge,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesShowCountMedium,
                slidesToScroll: slidesShowCountMedium
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: slidesShowCountSmall,
                slidesToScroll: slidesShowCountSmall,
                centerMode: centerMode,
                centerPadding: centerPadding
              }
            }
          ]
        };

        // Init this carousel with our settings
        $(this).slick(settings);

        // On before slide change
        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      // var sortGrid = function(sortFn) {
      //   var $allProductCells = $(".js-product-grid-item");
      //   var allProductIds = collectGridProductIds($allProductCells);

      //   allProductData = [];
      //   _.each(allProductIds, function(prodId, i) {
      //     allProductData.push(prodcat.data.getProduct(prodId));
      //   })
      //   //console.log(_.pluck(allProductData, "PRICE"));
      //   var sortedProductData = _.sortBy(allProductData, sortFn);
      //   //console.log(_.pluck(sortedProductData, "PRICE"));
      //   var sortedCells = [];
      //   _.each(sortedProductData, function(prod, i) {
      //     if (prod) {
      //       sortedCells.push($(".js-product-grid-item[data-product-id=" + prod.PRODUCT_ID + "]"));
      //     }
      //   });
      //   var $grid = $(".js-product-grid");
      //   $grid.empty();
      //   _.each(sortedCells, function(cell, i) {
      //     $grid.append($(cell));
      //   });
      //   $(document).trigger('grid.reflow');
      //   if (hasQuickshop && prodcat.ui.quickshop) {
      //     prodcat.ui.quickshop.reset($grid);
      //     prodcat.ui.quickshop.init($grid);
      //   }
      // };

      // $(document).on('mpp_sort:selected', function(e, sortFn) {
      //   var $productGrid = $('.js-product-grid', context);
      //   sortGrid(sortFn);
      //   site.util.grids.equalHeightsGrids($productGrid);
      // });

      // Filter event:
      //       $grids.on('productGrid.filter', function(event, prodsToShow, sort) {
      //         var $items = $('.js-product-grid-item', this);
      //         var $filteredItems = $items.filter(function() {
      //           return _.contains(prodsToShow, $(this).data('product-id'));
      //         });
      //         var $grid = $(this);
      //         var $container = $items.first().parent();
      //
      //         // First, undo any previous sorting we may have done:
      //         _resetFilterSort($grid);
      //
      //         // (Optionally) Sort:
      //         if (sort) {
      //           // Put a placeholder before the items we're going to sort so we can
      //           // un-sort them later (_resetFilterSort).
      //           $filteredItems.before(sortPlaceholder);
      //           // Reverse the array because we're prepending. Appending also works,
      //           // but this way we can target :first-child in css to get the primary
      //           // result in regimens.
      //           _.each(_.clone(prodsToShow).reverse(), function(id) {
      //             var $item = $filteredItems.filter('[data-product-id="' + id + '"]');
      //             $item.prependTo($container);
      //           });
      //         }
      //
      //         // Filter:
      //         $items.addClass('hidden');
      //         $filteredItems.removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });

      // Reset filter event:
      //       $grids.on('productGrid.showAll', function() {
      //         _resetFilterSort($(this));
      //         $('.js-product-grid-item', this).removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });
    }
  };

  //   function _resetFilterSort($grid) {
  //     var $items = $('.js-product-grid-item', $grid);
  //     $('.js-product-grid-sort-placeholder', $grid).each(function() {
  //       var id = $(this).data('placeholder-product-id');
  //       var $item = $items.filter('[data-product-id="' + id + '"]');
  //       $(this).after($item).remove();
  //     });
  //   }
})(jQuery);
